import React from "react";

import { PageProps } from "gatsby";
import { Ref } from "react";

import { graphql } from "gatsby";
import { useStaticQuery } from "gatsby";

import { forwardRef } from "react";
import { useContext } from "react";
import { useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";

import ThemeContext from "@Js/Context/ThemeContext";

import Seo from "@Js/Component/Seo";

import FileIcon from "@Js/Component/Icon/FileIcon";
import Gutenberg from "@Js/Component/Gutenberg";
import Heading from "@Js/Component/Heading";

import * as classNames from "@Css/Component/Page/TermsAndConditions.module.scss";

/**
 * @type WordpressData
 */
type WordpressData = {
    wordpress: {
        contactSettings: {
            companies: {
                companyOneName: string;
                companyOneTermsAndConditions: {
                    link: string;
                };
                companyTwoName: string;
                companyTwoTermsAndConditions: {
                    link: string;
                };
            };
        };
    };
};

/**
 * @type WordpressPageData
 */
type WordpressPageData = {
    wordpressPage: {
        content?: string;
        extraContent?: string;
        seo: {
            canonical: string;
            metaDesc: string;
            metaKeywords: string;
            metaRobotsNofollow: string;
            metaRobotsNoindex: string;
            opengraphAuthor: string;
            opengraphDescription: string;
            opengraphModifiedTime: string;
            opengraphPublishedTime: string;
            opengraphPublisher: string;
            opengraphSiteName: string;
            opengraphTitle: string;
            opengraphType: string;
            opengraphUrl: string;
            schema: {
                raw: string;
            };
            title: string;
        };
    };
};

/**
 * @type TermsAndConditionsProps
 */
export type TermsAndConditionsProps = PageProps;

/**
 * @const TermsAndConditions
 */
const TermsAndConditions = (props: TermsAndConditionsProps, ref: Ref<HTMLElement>) => {
    const {
        setHeaderBackground,
        setIsLogoColorless,
        setIsMenuColorless
    } = useContext(ThemeContext);

    const { t } = useTranslation();

    const data: WordpressData & WordpressPageData = useStaticQuery(graphql`
        query {
            wordpress {
                contactSettings {
                    companies {
                        companyOneName
                        companyOneTermsAndConditions {
                            link
                        }
                        companyTwoName
                        companyTwoTermsAndConditions {
                            link
                        }
                    }
                }
            }
            wordpressPage(isTermsAndConditionsPage: { eq: true }) {
                content
                extraContent
                seo {
                    canonical
                    metaDesc
                    metaKeywords
                    metaRobotsNofollow
                    metaRobotsNoindex
                    opengraphAuthor
                    opengraphDescription
                    opengraphModifiedTime
                    opengraphPublishedTime
                    opengraphPublisher
                    opengraphSiteName
                    opengraphTitle
                    opengraphType
                    opengraphUrl
                    schema {
                        raw
                    }
                    title
                }
            }
        }
    `);

    useLayoutEffect(() => {
        setHeaderBackground("#FFFFFF");

        setIsLogoColorless(false);
        setIsMenuColorless(false);
    }, []);

    return (
        <>
            <Seo { ...data.wordpressPage.seo } />
            <section className={ classNames.termsAndConditions } ref={ ref }>
                { data.wordpressPage.content && (
                    <Gutenberg className={ classNames.gutenberg } content={ data.wordpressPage.content } />
                ) }
                <section className={ classNames.termsAndConditions }>
                    <div className={ classNames.container }>
                        <div className={ classNames.companies }>
                            <section className={ classNames.company }>
                                <FileIcon className={ classNames.icon } />
                                <Heading className={ classNames.heading }>
                                    { data.wordpress.contactSettings.companies.companyOneName }
                                </Heading>
                                <a
                                    className={ classNames.link }
                                    href={ data.wordpress.contactSettings.companies.companyOneTermsAndConditions.link }
                                    target="_blank"
                                >
                                    <span className={ classNames.label }>{ t("Terms and Conditions") } (pdf)</span>
                                </a>
                            </section>
                            <section className={ classNames.company }>
                                <FileIcon className={ classNames.icon } />
                                <Heading className={ classNames.heading }>
                                    { data.wordpress.contactSettings.companies.companyTwoName }
                                </Heading>
                                <a
                                    className={ classNames.link }
                                    href={ data.wordpress.contactSettings.companies.companyTwoTermsAndConditions.link }
                                    target="_blank"
                                >
                                    <span className={ classNames.label }>{ t("Terms and Conditions") } (pdf)</span>
                                </a>
                            </section>
                        </div>
                    </div>
                </section>
            </section>
        </>
    );
};

export default forwardRef(TermsAndConditions);
