import React from "react";

import { DetailedHTMLProps } from "react";
import { Ref } from "react";
import { SVGAttributes } from "react";

import { forwardRef } from "react";

/**
 * @type FileIconProps
 */
export type FileIconProps = Omit<DetailedHTMLProps<SVGAttributes<SVGSVGElement>, SVGSVGElement>, "children">;

/**
 * @const FileIcon
 */
const FileIcon = (props: FileIconProps, ref: Ref<SVGSVGElement>) => {
    return (
        <svg { ...props } height="41.772" ref={ ref } viewBox="0 0 37.226 41.772" width="37.226">
            <path d="M 0.90039062 0 A 0.90008998 0.90008998 0 0 0 0 0.90039062 L 0 40.873047 A 0.90008998 0.90008998 0 0 0 0.90039062 41.773438 L 36.326172 41.773438 A 0.90008998 0.90008998 0 0 0 37.226562 40.873047 L 37.226562 9.0585938 A 0.90008998 0.90008998 0 0 0 36.962891 8.421875 L 28.804688 0.26367188 A 0.90008998 0.90008998 0 0 0 28.167969 0 L 0.90039062 0 z M 1.8007812 1.8007812 L 27.794922 1.8007812 L 35.427734 9.4316406 L 35.427734 39.972656 L 1.8007812 39.972656 L 1.8007812 1.8007812 z M 9.9003906 9.9609375 L 9.9003906 11.759766 L 27.900391 11.759766 L 27.900391 9.9609375 L 9.9003906 9.9609375 z M 9.9003906 15.960938 L 9.9003906 17.759766 L 27.900391 17.759766 L 27.900391 15.960938 L 9.9003906 15.960938 z M 9.9003906 21.960938 L 9.9003906 23.759766 L 18.900391 23.759766 L 18.900391 21.960938 L 9.9003906 21.960938 z" fill="currentColor" />
        </svg>
    );
};

export default forwardRef(FileIcon);
